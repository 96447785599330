<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-img">
                    <img :src="gameInfo.logo" alt="">
                </div>
                <div class="top-title no-warp">
                    {{gameInfo.title}}
                </div>
                <div class="top-btn" @click="()=>{isPlay = false}">
                    PLAY
                </div>                
            </div>
            <div class="gamewin" v-else>
                <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
            </div>
            <div class="details-description">
                <div class="des-top">
                    <span></span>
                    Description:
                </div>
                <div class="des-body warp-6">{{gameInfo.synopsis}}</div>
            </div>
            <div class="game-body">
                <div class="body-h">
                    <div class="noselect">More games</div>
                </div>
                <div class="hot-list">
                    <div
                        class="hot-card pointer"
                        v-for="items in gameList"
                        :key="items.id"
                        @click="goDetails(items)"
                    >
                        <img :src="items.logo" alt="">
                        <div class="title no-warp">{{items.title}}</div>
                    </div>
                    <span v-for="item in 12" :key="item"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game,similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },

            goDetails(item){
                this.gameInfo = item;
                this.isPlay = true;
                scrollToTop(0);

            }
        },
    }
</script>

<style lang="less" scoped>
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.detail{
    padding: 0 9px;
    box-sizing: border-box;
    .details-top{
        margin-bottom: 22px;
        div{
            width: 200px;
            margin: 0 auto;
            text-align: center;
        }
        .top-img{
            width: 200px;
            height: 200px;
            border-radius: 24px;
            margin:24px auto 4px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .top-title{
            height: 22px;
            margin-bottom: 6px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            line-height: 22px;
            text-align: center;
            font-style: normal;
        }
        .top-btn{
            width: 330px    ;
            height: 40px;
            line-height: 40px;
            background: #5F8CE5;
            border-radius: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            font-style: normal;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    .gamewin{
        width: 100%;
        height: calc(100vh - 50px);
        margin-bottom: 16px;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .details-description{
        padding: 7px 11px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        margin-bottom: 35px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #000;
        text-align: left;
        font-style: normal;
        .des-top{
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;
            span{
                display: inline-block;
                width: 4px;
                height: 14px;
                background: #5F8CE5;
                border-radius: 2px;
                margin-right: 4px;
            }
        }
        .des-body{
            font-size: 14px;
            line-height: 20px;
        }
    }
    .game-body{
        margin: 17px 0 14px;
        .body-h{
            margin-bottom: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            display: flex;
            justify-content: space-between;
        }
        .hot-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .hot-card{
                width: 81px;
                margin-bottom: 9px;
                img{
                    width: 81px;
                    height: 81px;
                    border-radius: 11px;
                }
                .title{
                    height: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 13px;
                    color: #000000;
                    line-height: 18px;
                    text-align: left;
                    font-style: normal;
                }
            }
            span{
                width: 81px;
            }
        }
    }
    .no-warp{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .warp-6{
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>