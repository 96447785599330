<template>
    <div style="padding: 0 12px;box-sizing: border-box">
        <adContent></adContent>
        <div class="class-list" v-if="showClass">
            <div class="class-l-t">
                <div class="card pointer"
                     v-for="item in classList"
                     :key="item.key_name"
                     @click="getKeyGame(item.key_name)"
                >
                    <img :src="item.logo" alt="">
                    <p>{{item.key_name}}</p>
                </div>
                <div class="close">
                    <img class="pointer" @click="showClass = false" src="@/assets/slices/close.png" alt="">
                </div>
            </div>
            <div class="class-l-b"></div>
        </div>
        <div class="top-list">
            <div
                class="top-cont pointer"
                v-for="item in topList"
                :key="item.Key_name"
                @click="getKeyGame(item.key_name)"
            >
                <img :src="item.logo" alt="">
                <p>{{item.key_name}}</p>
            </div>
        </div>
        <div class="game-body">
            <div class="body-h noselect">Ranking</div>
            <div class="body-b">
                <div
                    class="rank-card pointer"
                    v-for="items in rankList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="title no-warp">{{items.title}}</div>
                    <div class="play">PLAY</div>
                </div>
            </div>
        </div>
        <div class="game-body">
            <div class="body-h">
                <div class="noselect">hot games</div>
                <div class="more pointer" @click="goSelect('All')">MORE</div>
            </div>
            <div class="hot-list">
                <div
                    class="hot-card pointer"
                    v-for="items in gameList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="title no-warp">{{items.title}}</div>
                </div>
                <span v-for="item in 12" :key="item"></span>
            </div>
        </div>

        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import {
    getClassList,
    getGame
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
import topList from "@/utils/topclass";
export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: [],
                searchText: '',
                classList: [],
                sClass: '',
                topList:[],
                showClass: false,
                rankList: [],
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            this.getList();
            this.getClass();
        },
        methods: {
            goSelect(key){
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'more',
                        key
                    }
                })
            },
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            getKeyGame(key){
                if(key === 'All'){
                    this.showClass = true;
                }else{
                    this.$router.push({
                        path: '/selectGame',
                        query: {
                            id: 'more',
                            key
                        }
                    })
                }
            },
            async getList(){
                this.rankList = await getGame({count:15});
                this.gameList = await getGame({count:99});
            },
            async getClass(){
                this.classList = await getClassList();
                let res = await getGame({count:20});

                this.classList.forEach((item,i)=>{
                    item.logo = res[i].logo;
                })
                let list = [];
                for(let i=0;i<3;i++){
                    list.push({
                        key_name: this.classList[i].key_name,
                        logo: topList[i]
                    })
                }
                list.push({
                    key_name: 'All',
                    logo: topList[3]
                })
                this.topList = list;
            },
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.class-list{
    @media (min-width: 768px){
        width: 1200px;
        left: 50%;
        transform: translateX(-50%);
    }
    @media (max-width: 768px){
        width: 100%;
        left: 0;
    }
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 99999;
    .class-l-t{
        padding: 34px 12px 0;
        box-sizing: border-box;
        height: 400px;
        background: #B4D9F3;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;
        .card{
            padding-top: 7px;
            box-sizing: border-box;
            width: 85px;
            height: 76px;
            background: #80ABFF;
            border-radius: 8px;
            img{
                width: 45px;
                height: 45px;
                border-radius: 10px;
            }
            p{
                height: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                font-style: normal;
            }
        }
        .close{
            width: 100%;
            text-align: center;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
    .class-l-b{
        height: calc(100vh - 350px);
        background: rgba(0,0,0,0.5);
    }
}
.top-list{
    margin-top: 6px;
    display: flex;
    @media (min-width: 768px){
        .top-cont{
            width: 390px;
            margin: 0 5px;
        }
    }
    @media (max-width: 768px){
        .top-cont{
            width: 85px;
            margin: 0 5px;
        }
    }
    justify-content: space-between;
    text-align: center;
    .top-cont{
        height: 76px;
        background: #80ABFF;
        border-radius: 8px;
        padding-top: 12px;
        box-sizing: border-box;
        img{
            width: 30px;
            height: 30px;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 20px;
            font-style: normal;
        }
    }
}
.game-body{
    margin-bottom: 14px;
    .body-h{
        box-sizing: border-box;
        height: 32px;
        font-family: Inter, Inter;
        font-weight: normal;
        font-size: 20px;
        color: #383838;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: flex;
        justify-content: space-between;
    }
    .body-b{
        margin-top: 14px;
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px){
            &::-webkit-scrollbar{
                height: 7px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
        .rank-card{
            width: 85px;
            height: 123px;
            background: #80ABFF;
            border-radius: 10px;
            display: inline-block;
            margin-right: 10px;
            img{
                display: block;
                margin: 5px auto;
                width: 74px;
                height: 74px;
                border-radius: 10px;
            }
            .title{
                margin: 0 auto;
                width: 76px;
                height: 17px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 17px;
                text-align: left;
                font-style: normal;
            }
            .play{
                width: 52px;
                height: 16px;
                background: #FFFFFF;
                border-radius: 8px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 10px;
                color: #5F8CE5;
                line-height: 16px;
                text-align: center;
                font-style: normal;
                margin: 0 auto;
            }
        }
    }
    .hot-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .hot-card{
            width: 81px;
            margin-bottom: 9px;
            img{
                width: 81px;
                height: 81px;
                border-radius: 11px;
            }
            .title{
                height: 18px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 13px;
                color: #000000;
                line-height: 18px;
                text-align: left;
                font-style: normal;
            }
        }
        span{
            width: 81px;
        }
    }
}
.game-blurb{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>