<template>
    <div>
        <img class="top-icon" src="@/assets/slices/top.png" alt="" @click="goIndex">
    </div>
</template>

<script>
import { scrollToTop } from "@/utils/api";

export default {
    methods:{
        goIndex(){
            scrollToTop(0)
        }
    }
}
</script>

<style lang="less" scoped>
.top-icon{
    position: fixed;
    right: 4px;
    bottom: 100px;
    width: 54px;
    height: 54px;
}
</style>