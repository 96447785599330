<template>
    <div id="app">
        <headerNav></headerNav>
        <navMenu></navMenu>
        <router-view/>
        <footerMenu></footerMenu>
        <go-top></go-top>
    </div>
</template>
<script>
import headerNav from "@/components/headerNav/index"
import navMenu from '@/components/navMenu'
import footerMenu from '@/components/footer'
import goTop from "@/components/goTop/index.vue";
export default{
    components:{
        headerNav,
        navMenu,
        footerMenu,
        goTop
    }
}
</script>
<style lang="less">
@media (min-width: 768px) {
  #app{
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
  }
}
@media (max-width: 768px) {
  #app{
    padding-top: 50px;
  }
}
*{
  padding: 0;
  margin: 0;
}
body{
    background: #E9F3F2;
}
</style>
